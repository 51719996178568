<template>
  <div>
    <b-modal v-model="isShow" ref="" size="xl" centered @hidden="hide()">
      <template #modal-header>Acceptance</template>

      <b-row>
        <b-col cols="6">
          <h2 class="mr-sm-4 header-tablepage">
            {{ selectedItem.product_name }}
          </h2>
        </b-col>
        <b-col cols="6" class="text-right">
          <button
            @click="exportTemplate"
            type="button"
            class="btn button cancel-btn mb-2 mr-2"
          >
            <font-awesome-icon icon="file-export" class="pointer" />
            <span class="btn-text">Download Template </span>
          </button>
          <button
            @click="modalImport = true"
            type="button"
            class="btn button submit-btn mb-2"
          >
            <font-awesome-icon icon="file-import" class="pointer" />
            <span class="btn-text">Import </span>
          </button>
        </b-col>
        <b-col cols="12">
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
          >
            <template v-slot:cell(serial)="{ item, index }">
              <InputText
                v-if="selectedItem.is_serial"
                v-model="item.serial"
                placeholder="Serial"
                class="input-tb mb-0 mb-0"
                :class="{ 'mt-4': $v.items.$each.$iter[index].serial.$error }"
                type="text"
                @input="(val, e) => onInputSerial(val, e, index)"
                :isValidate="$v.items.$each.$iter[index].serial.$error"
                :v="$v.items.$each.$iter[index].serial"
              ></InputText>
              <div v-else>-</div>
            </template>
            <template v-slot:cell(quantity)="{ item, index }">
              <InputText
                v-model="item.quantity"
                placeholder="จำนวน"
                type="number"
                class="input-tb mb-0"
                v-if="!selectedItem.is_serial"
                @onKeyup.enter="handleProductQuantityInput($event, item)"
                @blur="handleProductQuantityInput($event, item)"
                @input="(val, e) => limitQuantity(val, e, index)"
              ></InputText>
              <div v-else>
                {{
                  selectedItem.is_serial ? 1 : selectedItem.received_available
                }}
              </div>
            </template>
            <template v-slot:cell(weight)="{ item, index }">
              <InputText
                v-model="item.format_weight"
                placeholder="Weight"
                @onKeypress="handleKeyPress"
                decimalPoint="2"
                type="text"
                class="input-tb mb-0"
                @input="(val, e) => formatCurrencyWeght(val, e, index)"
              ></InputText>
            </template>
            <template v-slot:cell(cost)="{ item, index }">
              <InputText
                v-model="item.format_cost"
                placeholder="Cost (Per piece)"
                @onKeypress="handleKeyPress"
                @input="(val, e) => formatCurrencyCost(val, e, index)"
                type="text"
                decimalPoint="2"
                class="input-tb mb-0"
              ></InputText>
            </template>
            <template v-slot:cell(total_cost)="{ item }">
              {{ (item.cost * item.quantity) | numeral("0,0.00") }}
            </template>
            <template v-slot:cell(action)="{ item }">
              <div v-if="selectedItem.is_serial">
                <b-button
                  variant="icon"
                  class="px-0"
                  @click="deleteProduct(item)"
                >
                  <b-icon icon="trash-fill"></b-icon>
                </b-button></div
            ></template>
          </b-table>
        </b-col>
        <b-col cols="12" v-if="selectedItem.is_serial">
          <div class="text-center card-dashed py-3">
            <button
              type="button"
              class="btn button submit-btn"
              :disabled="items.length >= selectedItem.received_available"
              @click="addItem()"
            >
              <font-awesome-icon icon="plus-square" class="pointer" />
              <span class="btn-text">Add Item </span>
            </button>
          </div>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-between mt-3">
        <div class="cols-6">
          <div class="d-flex align-items-center">
            <p class="mr-2 text-width text-black">Invoice Number</p>
            <InputText
              v-model="form.tax_invoice_no"
              type="text"
              placeholder="Invoice Number"
              class="mb-2 custom-input-text"
              :v="$v.form.tax_invoice_no"
              :isValidate="$v.form.tax_invoice_no.$error"
            ></InputText>
          </div>

          <div class="d-flex align-items-center">
            <p class="mr-2 text-width text-black">Invoice Date</p>

            <InputDatePickerFilter
              textFloat=""
              class="mb-1"
              name="valid-form"
              ref="valid-form"
              format="dd/MM/yyyy "
              v-model="form.tax_invoice_date"
              :v="$v.form.tax_invoice_date"
              :isValidate="$v.form.tax_invoice_date.$error"
              :minDatetime="issueDate"
              type="date"
            />
          </div>

          <InputTextArea
            textFloat="Receipt Note"
            type="text"
            class="mt-0 custom-input"
            placeholder="Receipt Note"
            rows="3"
            name="message"
            v-model="form.remark"
          ></InputTextArea>
        </div>
        <div>
          <div class="justify-content-between mt-3 d-flex">
            <p class="text-secondary text-right w-250">
              Cost Of Admission This Time
            </p>
            <p class="text-black font-weight-bold w-150px text-right">
              {{ totalCost | numeral("0,0.00") }} Baht
            </p>
          </div>
          <div class="justify-content-between d-flex mt-2">
            <p class="text-secondary text-right w-250">
              Waiting For All Inspections
            </p>
            <p class="text-black font-weight-bold text-right">
              {{ selectedItem.received_available }}
              Items
            </p>
          </div>
          <div class="justify-content-between d-flex mt-2">
            <p class="text-secondary text-right w-250">Received</p>
            <p class="text-black font-weight-bold text-right">
              {{ totalQuantity }} Items
            </p>
          </div>
          <div class="justify-content-between d-flex mt-2">
            <p class="text-secondary text-right w-250">Still Left</p>
            <p class="text-error font-weight-bold text-right">
              {{ receivedAvailable }} Items
            </p>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <b-row>
          <b-col class="text-left">
            <b-button @click.prevent="hide()" class="border-btn"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button class="submit-btn" @click="save()">Save</b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-modal
      v-model="modalImport"
      no-close-on-backdrop
      centered
      title="Import Product"
      hide-footer
    >
      <div>
        <b-row class="no-gutters">
          <b-col md="12">
            <UploadFileV2
              textFloat="File"
              placeholder="Please Choose File"
              format="excel"
              name="file"
              :fileName="fileName"
              text="*Please upload only file .xlsx"
              v-on:onFileChange="onFileChange"
              v-on:delete="deleteFile"
              v-model="file.excel_file"
              id="uploadfile"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button size="md" class="btn-cancel" @click="hideImport()"
              >Cancel</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              id="upload"
              class="main-color btn-save color-btn"
              size="md"
              :disabled="isDisable"
              @click="importTemplate"
              >Upload</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import { requiredIf } from "vuelidate/lib/validators";
import { required } from "vuelidate/lib/validators";

export default {
  components: { UploadFileV2 },
  props: { issueDate: { required: false } },
  validations: {
    form: {
      tax_invoice_date: { required },
      tax_invoice_no: { required },
    },
    items: {
      $each: {
        serial: {
          required: requiredIf(function (item) {
            return this.selectedItem.is_serial;
          }),
        },
      },
    },
  },
  data() {
    return {
      isShow: false,
      form: {
        received_items: [],
        tax_invoice_no: "",
        tax_invoice_date: "",
        remark: "",
        po_product_id: "",
      },
      items: [],
      isBusy: false,
      fields: [
        { label: "No.", key: "index", thStyle: { width: "10%" } },

        { label: "Serial No.", key: "serial", thStyle: { width: "12%" } },
        {
          label: "Weight",
          key: "weight",
          thStyle: { width: "10%" },
        },
        {
          label: "Cost (Per piece)",
          key: "cost",
          thStyle: { width: "20%" },
        },
        {
          label: "Quantity",
          key: "quantity",
          thStyle: { width: "10%" },
        },
        { label: "Total Cost", key: "total_cost", thStyle: { width: "20%" } },
        { label: "Action", key: "action", thStyle: { width: "10%" } },
      ],
      selectedItem: {},
      id: this.$route.params.id,
      modalImport: false,
      file: {},
      isDisable: true,
      fileName: null,
    };
  },
  computed: {
    totalCost: function () {
      if (this.items.length == 0) {
        return 0;
      }

      let costSum = this.form.received_items.reduce(
        (sum, item) => sum + (Number(item.cost) || 0),
        0
      );
      if (this.selectedItem && !this.selectedItem.is_serial) {
        return this.items[0].cost * this.items[0].quantity;
      }
      return costSum;
    },
    totalQuantity: function () {
      if (this.items.length == 0) {
        return 0;
      }

      let qSum = this.form.received_items.reduce(
        (sum, item) => sum + (Number(item.quantity) || 0),
        0
      );

      return qSum;
    },
    receivedAvailable: function () {
      if (this.items.length == 0) {
        return this.selectedItem.received_available;
      }

      let receiveSum = this.form.received_items.reduce(
        (sum, item) => sum - Number(item.quantity),
        this.selectedItem.received_available
      );

      return receiveSum;
    },
  },
  methods: {
    showModal(item) {
      this.isShow = true;
      this.selectedItem = { ...item };
      if (!item.is_serial) {
        this.items.push({
          index: this.items.length + 1,
          serial: "",
          weight: null,
          cost: null,
          quantity: 1,
          total_cost: null,
          format_weight: null,
        });
        this.form.received_items = this.items;
      } else {
        this.addItem();
      }
      this.form.po_product_id = item.id;
    },
    hide() {
      this.isShow = false;
      this.form = {
        received_items: [],
      };
      this.$v.$reset();

      // this.$v.form.received_items.$reset();
      this.items = [];
      this.form = {};
    },
    async exportGoodReceipt(pdf, name) {
      const byteCharacters = window.atob(pdf);
      const byteNumbers = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const blob = new Blob([byteNumbers], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    },
    deleteFile() {
      this.file.excel_file = null;
      this.fileName = null;
      this.isDisable = true;
    },
    onFileChange(file) {
      this.fileName = file.name;
      this.file.document_no = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.file.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    async importTemplate() {
      try {
        this.$bus.$emit("showLoading");
        const data = await this.axios.post(`/po/importProductReceived`, {
          base64File: this.file.excel_file,
        });

        if (data.data.result === 1) {
          if (data.data.detail.length > this.selectedItem.received_available)
            data.data.detail.length = this.selectedItem.received_available;
          if (this.selectedItem.is_serial == 0) data.data.detail.length = 1;
          this.items = data.data.detail.map((x, n) => {
            const quantity =
              x.quantity == 0
                ? 1
                : x.quantity > this.selectedItem.received_available
                ? this.selectedItem.received_available
                : x.quantity;
            return {
              index: n + 1,
              serial: x.serial,
              weight: x.weight,
              cost: x.cost,
              quantity: quantity,
              total_cost: x.cost * x.quantity,
              format_weight: x.weight,
              format_cost: x.cost,
            };
          });

          this.form.received_items = this.items;

          this.successAlert();
        } else {
          this.errorAlert(data.data.message);
        }
        this.hideImport();
      } catch (error) {
        console.log(error);
        this.errorAlert("Import Fails");
      } finally {
        this.$bus.$emit("hideLoading");
      }
    },
    hideImport() {
      this.file = {};
      this.isDisable = true;
      this.fileName = null;
      this.modalImport = false;
    },
    async exportTemplate() {
      const res = await this.axios(`/po/ExportReceiveProductTemplate`);
      if (res.data.result === 1 && res.data.detail) {
        const byteCharacters = atob(res.data.detail);
        const byteNumbers = new Uint8Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const blob = new Blob([byteNumbers], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "ReceiveProductTemplate.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
        this.successAlert();
      }
    },
    async save() {
      try {
        this.$v.form.$touch();
        this.$v.items.$touch();
        if (this.$v.form.$error || this.$v.items.$error) {
          return;
        }
        this.$bus.$emit("showLoading");
        this.form.received_items = this.form.received_items.map((x) => ({
          ...x,
          cost: x.cost || 0,
          weight: x.weight || 0,
        }));
        let payload = { ...this.form, po_id: this.id };
        const res = await this.axios.post(`/po/ReceiveProduct`, payload);

        if (res.data.result === 1) {
          await this.exportGoodReceipt(
            res.data.detail.document_base64,
            res.data.detail.goods_receipt_no
          );
          this.successAlert();
          this.$emit("updateReceiveProduct");
          this.isShow = false;
        } else {
          this.errorAlert(res.data.message);
        }
        this.$bus.$emit("hideLoading");
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
    addItem() {
      if (this.items.length >= this.selectedItem.received_available) {
        return;
      }

      this.items.push({
        index: this.items.length + 1,
        serial: "",
        weight: null,
        cost: null,
        quantity: 1,
        total_cost: null,
        format_weight: null,
      });

      this.form.received_items = this.items;
    },
    deleteProduct(data) {
      this.items = this.items
        .filter((x) => x.index !== data.index)
        .map((el, index) => {
          return { ...el, index: index + 1 };
        });
      this.form.received_items = this.items.map((el, index) => {
        return { ...el, index: index + 1 };
      });
    },
    handleKeyPress(event) {
      const char = event.key;

      if (!/[\d.,]/.test(char)) {
        event.preventDefault();
      }
      if (
        (char === "." && event.target.value.includes(".")) ||
        (char === "," && event.target.value.includes(","))
      ) {
        event.preventDefault();
      }
    },
    formatCurrencyWeght(val, e, index) {
      let value = val.replace(/[^0-9.]/g, "");

      if (value === "") {
        this.form.received_items[index].format_weight = "";
        this.form.received_items[index].weight = "";
        return;
      }
      let numericValue = parseFloat(value);
      const formattedValue = Number(value).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      });

      this.form.received_items[index].weight = numericValue;
      this.form.received_items[index].format_weight = formattedValue;
    },
    formatCurrencyCost(val, e, index) {
      let value = val.replace(/[^0-9.]/g, "");

      if (value === "") {
        this.form.received_items[index].format_cost = "";
        this.form.received_items[index].cost = "";
        return;
      }
      let numericValue = parseFloat(value);
      const formattedValue = Number(value).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      });

      this.form.received_items[index].cost = numericValue;
      this.form.received_items[index].format_cost = formattedValue;
    },
    limitQuantity(val, e, index) {
      if (val > this.selectedItem.received_available) {
        this.form.received_items[index].quantity =
          this.selectedItem.received_available;
        e.target.value = this.selectedItem.received_available;
      }
    },
    handleProductQuantityInput(event, item) {
      if (!item.quantity) {
        item.quantity = 1;
        return;
      }
    },
    onInputSerial(val, e, index) {
      this.form.received_items[index].serial = val;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.btn-cancel {
  color: #fff;
  background-color: #39424e;
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}

.input-tb ::v-deep input {
  text-align: center !important;
}

.card-dashed {
  border: 1px dashed;
  border-color: var(--primary-color);
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.text-width {
  width: 110px;
}

.custom-input ::v-deep textarea {
  width: 335px;
}

.custom-input {
  width: 335px;
}

.custom-input-text {
  width: 215px;
}

.w-250 {
  width: 250px;
}

.w-150px {
  width: 150px !important;
}
</style>
